<template>
    <onboarding-layout>
        <bottom-sheet-header> Dexxter 2.0 - klaar voor de toekomst! </bottom-sheet-header>
        <p>
            Om futureproof te blijven, hebben we een paar technische aanpassingen gedaan.<br />Voortaan dien je in te
            loggen of te registreren via <a href="https://go.dexxter.be">https://go.dexxter.be</a>.
            <br />
            Je inloggegevens blijven dezelfde en heel je historiek blijft behouden.<br />Heb je feedback op de nieuwe
            versie? Stuur gerust naar <a href="mailto:hello@dexxter.be">hello@dexxter.be</a>!
        </p>
    </onboarding-layout>
</template>

<script>
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader';
import OnboardingLayout from '../layouts/OnboardingLayout.vue';

export default {
    components: {
        BottomSheetHeader,
        OnboardingLayout
    }
};
</script>
<style lang="scss" scoped></style>
