<template>
    <div class="Wrapper" v-bind="$attrs">
        <div class="left">
            <div class="logo-and-image">
                <img class="logo" src="https://app.dexxter.be/img/DEXXTER-V1_PNG.763fd558.png" alt="dexxter-logo" />

                <img
                    src="https://storage.googleapis.com/dexxter-production-static-files/illustrations/analytics.png"
                    alt="feature-image"
                    class="feature-image"
                />
            </div>

            <div v-if="isLoggedIn" class="logout">
                <a class="link-small" @click="logout">{{ $t('general.logout') }}</a>
            </div>
        </div>
        <div class="right">
            <div class="content-container">
                <div><slot name="stepperHeader"></slot></div>
                <div>
                    <h1 class="complete-registration-title mb-2"><slot name="title" /></h1>
                    <h4 v-if="hasLeadTitleSlot" class="lead-text"><slot name="lead-title" /></h4>
                    <p class="lead-text"><slot name="lead-text" /></p>
                </div>
                <div class="overflow-visible">
                    <slot />
                </div>
                <div v-if="isLoggedIn" class="mobile-logout">
                    <a class="link-small" @click="logout">{{ $t('general.logout') }}</a>
                </div>
            </div>
        </div>

        <base-modal />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
// --- Components ---
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import BaseModal from '@/components/Modal/BaseModal';

export default {
    name: 'OnboardingLayout',
    components: { BaseModal },
    mixins: [screenSizeMixin],
    inheritAttrs: false,
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState('auth', ['isLoggedIn']),
        hasLeadTitleSlot() {
            return !!this.$slots.leadTitle;
        }
    },

    methods: {
        ...mapActions('auth', ['logout'])
    }
};
</script>

<style lang="scss">
@import '../sass/mixin';

@include respond-to('phone') {
    .Wrapper {
        display: block !important;
        min-height: 100vh;
    }
}

.logo {
    width: 70%;
}

.mobile-logout,
.logout {
    border: 1px solid $dexxter-blue;
    border-radius: 5px;
    padding: 10px 0;
    margin-bottom: 0;
    text-align: center;
    margin-top: 3.5rem;
}

.logout {
    display: none;
    margin: 5vh 2vw 0;
    width: 60%;
}

@media screen and (min-width: $md) {
    .mobile-logout {
        display: none;
    }

    .logout {
        display: block;
    }
}

.Wrapper {
    display: grid;
    width: 100vw;
    height: 100vh;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 0 0;
    grid-template-areas: '. .';

    @media screen and (min-width: $md) {
        grid-template-columns: 0.36fr 0.64fr;
    }
}

.Wrapper .left {
    display: none;

    @media screen and (min-width: $md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .logo-and-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -100px;

        .logo {
            margin: 20px auto;
            width: 50%;
            top: 12%;
        }

        .feature-image {
            width: 85%;
        }
    }
}
.Wrapper .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    min-height: 100vh;

    .content-container {
        width: 100%;
        overflow: auto;
        padding: 5% 8.333%;

        .complete-registration-title {
            color: $dexxter-dark !important;
            font-weight: bold;
            font-size: 2rem !important;
            font-family: 'Cerebri Sans', sans-serif;
        }

        .lead-text {
            color: $dexxter-dark !important;
        }
    }
}
</style>
